var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Slider With Input" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeInput) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "b-form-group",
            { staticClass: "mr-2" },
            [
              _c(
                "b-form-select",
                {
                  model: {
                    value: _vm.ldot,
                    callback: function($$v) {
                      _vm.ldot = $$v
                    },
                    expression: "ldot"
                  }
                },
                _vm._l(100, function(n) {
                  return _c(
                    "b-form-select-option",
                    { key: n, attrs: { value: n } },
                    [_vm._v(" " + _vm._s(n) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            [
              _c("b-form-input", {
                model: {
                  value: _vm.rdot,
                  callback: function($$v) {
                    _vm.rdot = $$v
                  },
                  expression: "rdot"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("vue-slider", {
        attrs: { direction: _vm.direction },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }