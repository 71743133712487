var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Multiple Slider" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeMultiple) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Set multiple value to slider for multiple ")]),
        _c("code", [_vm._v("dots")])
      ]),
      _c("vue-slider", {
        staticClass: "mb-2",
        attrs: { direction: _vm.direction },
        model: {
          value: _vm.value_2,
          callback: function($$v) {
            _vm.value_2 = $$v
          },
          expression: "value_2"
        }
      }),
      _c("vue-slider", {
        staticClass: "mb-2",
        attrs: { direction: _vm.direction },
        model: {
          value: _vm.value_3,
          callback: function($$v) {
            _vm.value_3 = $$v
          },
          expression: "value_3"
        }
      }),
      _c("vue-slider", {
        attrs: { direction: _vm.direction },
        model: {
          value: _vm.value_4,
          callback: function($$v) {
            _vm.value_4 = $$v
          },
          expression: "value_4"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }