var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Range of values" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeRange) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use ")]),
        _c("code", [_vm._v("min")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("max")]),
        _c("span", [_vm._v(" with ")]),
        _c("code", [_vm._v("<vue-slider>")]),
        _c("span", [_vm._v(" for range slider.")])
      ]),
      _c("vue-slider", {
        attrs: { min: 0, max: 1, direction: _vm.direction, interval: 0.01 },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }