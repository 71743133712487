var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Slider Colors - Handles" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeColors) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("You can use ")]),
        _c("code", [_vm._v("process-style")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("dot-style")]),
        _c("span", [_vm._v(" for style process bar and dot")])
      ]),
      _c("h6", [_vm._v("Default / Primary Color Slider")]),
      _c("vue-slider", {
        staticClass: "mb-2",
        attrs: { tooltip: "none", direction: _vm.direction },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _c("h6", [_vm._v("Secondary Color Slider")]),
      _c("vue-slider", {
        staticClass: "mb-2 vue-slider-secondary",
        attrs: { tooltip: "none", direction: _vm.direction },
        model: {
          value: _vm.value1,
          callback: function($$v) {
            _vm.value1 = $$v
          },
          expression: "value1"
        }
      }),
      _c("h6", [_vm._v("Success Color Slider")]),
      _c("vue-slider", {
        staticClass: "mb-2 vue-slider-success",
        attrs: { tooltip: "none", direction: _vm.direction },
        model: {
          value: _vm.value2,
          callback: function($$v) {
            _vm.value2 = $$v
          },
          expression: "value2"
        }
      }),
      _c("h6", [_vm._v("Danger Color Slider")]),
      _c("vue-slider", {
        staticClass: "mb-2 vue-slider-danger",
        attrs: { tooltip: "none", direction: _vm.direction, fixed: true },
        model: {
          value: _vm.value4,
          callback: function($$v) {
            _vm.value4 = $$v
          },
          expression: "value4"
        }
      }),
      _c("h6", [_vm._v("Warning Color Slider")]),
      _c("vue-slider", {
        staticClass: "mb-2 vue-slider-warning",
        attrs: { tooltip: "none", direction: _vm.direction },
        model: {
          value: _vm.value3,
          callback: function($$v) {
            _vm.value3 = $$v
          },
          expression: "value3"
        }
      }),
      _c("h6", [_vm._v("Info Color Slider")]),
      _c("vue-slider", {
        staticClass: "mb-2 vue-slider-info",
        attrs: {
          tooltip: "none",
          "min-range": 20,
          "max-range": 50,
          direction: _vm.direction
        },
        model: {
          value: _vm.value5,
          callback: function($$v) {
            _vm.value5 = $$v
          },
          expression: "value5"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }