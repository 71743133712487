var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("slider-single"),
      _c("slider-multiple"),
      _c("slider-ranger-value"),
      _c("slider-rtl"),
      _c("slider-lazy"),
      _c("slider-disabled"),
      _c("slider-adsorb"),
      _c("slider-contained"),
      _c("slider-behavior"),
      _c("slider-color"),
      _c("slider-input"),
      _c("slider-vertical")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }